import React, { useMemo } from 'react'
import { useImportContext } from './context'
import { Card } from '../../../../../../../shared/card/card'

export const StepColumn = () => {
  const importContext = useImportContext()

  const updateEmailIndex = email => importContext.updateIndexes({ ...importContext.indexes, email })
  const updateFirstNameIndex = firstName => importContext.updateIndexes({ ...importContext.indexes, firstName })
  const updateLastNameIndex = lastName => importContext.updateIndexes({ ...importContext.indexes, lastName })
  const updateRoleIndex = role => importContext.updateIndexes({ ...importContext.indexes, role })
  const updateGroupIndex = group => importContext.updateIndexes({ ...importContext.indexes, group })
  const updateContentIndex = content => importContext.updateIndexes({ ...importContext.indexes, content })
  const updateTemplateIndex = template => importContext.updateIndexes({ ...importContext.indexes, template })
  const updateLibraryIndex = library => importContext.updateIndexes({ ...importContext.indexes, library })

  return (
    <React.Fragment>
      <Card title="Columns">
        <SelectHeader label="email" headers={importContext.headers} value={importContext.indexes.email} onChange={updateEmailIndex} />
        <SelectHeader
          label="first name"
          headers={importContext.headers}
          value={importContext.indexes.firstName}
          onChange={updateFirstNameIndex}
        />
        <SelectHeader
          label="last name"
          headers={importContext.headers}
          value={importContext.indexes.lastName}
          onChange={updateLastNameIndex}
        />
        <SelectHeader label="roles" headers={importContext.headers} value={importContext.indexes.role} onChange={updateRoleIndex} />
        <SelectHeader label="groups" headers={importContext.headers} value={importContext.indexes.group} onChange={updateGroupIndex} />
        <SelectHeader
          label="contents"
          headers={importContext.headers}
          value={importContext.indexes.content}
          onChange={updateContentIndex}
        />
        <SelectHeader
          label="templates"
          headers={importContext.headers}
          value={importContext.indexes.template}
          onChange={updateTemplateIndex}
        />
        <SelectHeader
          label="libraries"
          headers={importContext.headers}
          value={importContext.indexes.library}
          onChange={updateLibraryIndex}
        />
      </Card>
    </React.Fragment>
  )
}

const SelectHeader = ({ label, headers, value, onChange }) => {
  const selectValue = useMemo(() => (value === undefined ? -1 : value), [value])

  const handleChange = e => {
    let value = parseInt(e.target.value)
    onChange && onChange(value === -1 ? undefined : value)
  }

  return (
    <div className="m-1">
      <label className="form-label text-capitalize">
        {label}
      </label>
      <select className="form-control" value={selectValue} onChange={handleChange}>
        <option value={-1}>select</option>
        {headers &&
          headers.map((header, i) =>
            <option key={i} value={i}>
              {header}
            </option>
          )}
      </select>
    </div>
  )
}
