import { useDisplaySysAdmin } from '../../../../../../role/role.component'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { IconCopy, IconEdit, IconFile, IconGroup, IconMove, IconUser } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuDetails, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'

export const NodeLibraryMenuFull = (url, onAddUnityPackage) => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const displaySysAdmin = useDisplaySysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="Sys Admin" show={displaySysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={displaySysAdmin} />
      <MenuItem Icon={IconFile} text="add unity package" onClick={onAddUnityPackage} show={displaySysAdmin} />

      <MenuItemHeader text="Org" show={displaySysAdmin} />
      <MenuDetails to={`${url}`} show={roleContent.accessRead} pin={false} />
      <MenuEdit to={`${url}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} disabled={false} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconUser} text="user permissions" to={`${url}/user-permission`} show={roleContent.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${url}/group-permission`} show={roleContent.accessRead} />
      <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${url}/activity`} show={roleContent.accessRead} />
    </Menu>
  )
}

export const NodeLibraryMenuPartial = url => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const displaySysAdmin = useDisplaySysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="Sys Admin" show={displaySysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="Org" show={displaySysAdmin} />
      <MenuEdit to={`${url}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} disabled={false} show={roleContent.accessUpdate} />
      <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />
    </Menu>
  )
}
