import { useMemo } from "react"

export const useMetadataType = (item, type, value) => {
    return useMemo(
      () => {
        if (item && item.metaData) {
          let metaData = item.metaData.toLowerCase()
          metaData = JSON.parse(metaData)
          let lowerType = type.toLowerCase()
          if (metaData[lowerType]) {
            return metaData[lowerType].includes(value.toLowerCase())
          }
  
          return false
        }
      },
      [item, type, value]
    )
  }
  
  export const useGetMetadataValue = (item, key) => {
    return useMemo(
      () => {
        if (item && item.metaData) {
          try {
            let metaData = item.metaData.toLowerCase()
          metaData = JSON.parse(metaData)
          return metaData[key]
          } catch {
            return null
          }
        }
      },
      [item, key]
    )
  }
  
  export const useGetByDataType = (dataType, assets) => {
    return useMemo(
      () => {
        return assets.find(asset => {
          if (!asset.metaData) {
            return false
          }
  
          let metaData = JSON.parse(asset.metaData)
  
          if (!metaData.DataType) {
            return false
          }
  
          return dataType.toLowerCase() === metaData.DataType.toLowerCase()
        })
      },
      [dataType, assets]
    )
  }
  

export const useMetadataTypeIsCaffeine = (form) => {
  return useMetadataType(form, 'CourseAssemblyName', 'caffeine')
}