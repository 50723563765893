import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd, IconCsv, IconDetails, IconMenu } from '../../../../../shared/icon/icon'
import { useApiFormUserInvite, useUserInviteProgress } from '../../../../../api-new/state/content'
import { CardFullHeight } from '../../../../../shared/card/card-full-height'
import { ButtonPrimary, ButtonSecondary } from '../../../../../shared/component/button'
import { Menu, MenuAdd, MenuItem } from '../../../../../shared/menu/menu'
import { UserList } from './user-login-add-new/user-list'
import { AddUserContext, useAddUserContext } from './user-login-add-new/context'
import { ImportCSV } from './user-login-add-new/import-csv/import-csv'
import { useModalActionID } from '../../../../../shared/component/modal-dialog'
import { InviteModalProgress } from './user-login-add-new/invite-modal-progress'
import { useHistory } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useUserListData } from './user-login-add-new/user-list-data'
import { ModalExample } from './user-login-add-new/modal-example'
import { SelectRoleButton, SelectRoleDropdown, SelectRoleModal } from './user-login-add-new/select/select-roles'
import { SelectGroupButton, SelectGroupDropdown, SelectGroupModal } from './user-login-add-new/select/select-groups'
import { SelectContentButton, SelectContentDropdown, SelectContentModal } from './user-login-add-new/select/select-content'
import { SelectLibraryButton, SelectLibraryDropdown, SelectLibraryModal } from './user-login-add-new/select/select-libraries'
import { SelectTemplateButton, SelectTemplateDropdown, SelectTemplateModal } from './user-login-add-new/select/select-template'

const newUser = { email: '', firstName: '', lastName: '' }

export const UserLoginAddNew = () => {
  return (
    <AddUserContext>
      <UserLoginAddNewHold />
    </AddUserContext>
  )
}

export const UserLoginAddNewHold = () => {
  const history = useHistory()

  const addUserContext = useAddUserContext()

  const apiFormUserInvite = useApiFormUserInvite()

  const handleItemImport = () => refImport.current.open()

  const onProgressComplete = () => {
    setUsers([])
    updatedDisable(false)
  }

  const [disabled, updatedDisable] = useState(false)

  const [inviteAllStart, inviteAllProgress] = useUserInviteProgress(onProgressComplete)

  const [progressTarget, progressOpen, progressHide] = useModalActionID()

  const handleContinue = () => {
    progressHide()
    history.replace('/org/user/list')
  }

  const handleBack = () => {
    history.goBack()
  }

  const refImport = useRef()

  const [users, setUsers, addUser, updateUser, deleteUser, deleteUserIdentifier] = useUserListData()

  useEffect(
    () => {
      if (users.length === 0) {
        setUsers([{ ...newUser }])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users]
  )

  const handleAddItem = () => addUser({ ...newUser })

  const handleChange = (index, user) => {
    updateUser(index, user)
  }

  const handleDelete = index => deleteUser(index)

  const handleInvite = async index => {
    let user = users[index]

    if (user) {
      user.isSubmitting = true

      updateUser(index, user)

      let value = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      }

      let commonRoleIDs = addUserContext.commonRoles.map(i => i.id)
      let commonGroupIDs = addUserContext.commonGroups.map(i => i.id)
      let commonContentIDs = addUserContext.commonContents.map(i => i.id)
      let commonTemplateIDs = addUserContext.commonTemplates.map(i => i.id)
      let commonLibraryIDs = addUserContext.commonLibraries.map(i => i.id)

      let selectedRoleIDs = user.selectedRoles ? user.selectedRoles.map(i => i.id) : []
      let selectedGroupIDs = user.selectedGroups ? user.selectedGroups.map(i => i.id) : []
      let selectedContentIDs = user.selectedContents ? user.selectedContents.map(i => i.id) : []
      let selectedTemplateIDs = user.selectedTemplates ? user.selectedTemplates.map(i => i.id) : []
      let selectedLibraryIDs = user.selectedLibraries ? user.selectedLibraries.map(i => i.id) : []

      value.roleIDs = [...commonRoleIDs, ...selectedRoleIDs]
      value.groupIDs = [...commonGroupIDs, ...selectedGroupIDs]
      value.contentIDs = [...commonContentIDs, ...selectedContentIDs]
      value.templateIDs = [...commonTemplateIDs, ...selectedTemplateIDs]
      value.libraryIDs = [...commonLibraryIDs, ...selectedLibraryIDs]

      await apiFormUserInvite.post(value)

      deleteUserIdentifier(user.identifier)
    }
  }

  const handleInviteAll = () => {
    let commonRoleIDs = addUserContext.commonRoles.map(i => i.id)
    let commonGroupIDs = addUserContext.commonGroups.map(i => i.id)
    let commonContentIDs = addUserContext.commonContents.map(i => i.id)
    let commonTemplateIDs = addUserContext.commonTemplates.map(i => i.id)
    let commonLibraryIDs = addUserContext.commonLibraries.map(i => i.id)

    const values = users.map(user => {
      let value = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      }

      let selectedRoleIDs = user.selectedRoles ? user.selectedRoles.map(i => i.id) : []
      let selectedGroupIDs = user.selectedGroups ? user.selectedGroups.map(i => i.id) : []
      let selectedContentIDs = user.selectedContents ? user.selectedContents.map(i => i.id) : []
      let selectedTemplateIDs = user.selectedTemplates ? user.selectedTemplates.map(i => i.id) : []
      let selectedLibraryIDs = user.selectedLibraries ? user.selectedLibraries.map(i => i.id) : []

      value.roleIDs = [...commonRoleIDs, ...selectedRoleIDs]
      value.groupIDs = [...commonGroupIDs, ...selectedGroupIDs]
      value.contentIDs = [...commonContentIDs, ...selectedContentIDs]
      value.templateIDs = [...commonTemplateIDs, ...selectedTemplateIDs]
      value.libraryIDs = [...commonLibraryIDs, ...selectedLibraryIDs]

      return value
    })

    updatedDisable(true)
    inviteAllStart(values)
    progressOpen()
  }

  const onImport = ({ rows, indexes, roleValues, groupValues, contentValues, templateValues, libraryValues }) => {
    let results = []

    rows.forEach(row => {
      let roleItems = row[indexes.role] ? row[indexes.role].toLowerCase().split(',') : []
      let groupItems = row[indexes.group] ? row[indexes.group].toLowerCase().split(',') : []
      let contentItems = row[indexes.content] ? row[indexes.content].toLowerCase().split(',') : []
      let templateItems = row[indexes.template] ? row[indexes.template].toLowerCase().split(',') : []
      let libraryItems = row[indexes.library] ? row[indexes.library].toLowerCase().split(',') : []

      let selectedRoles = []
      let selectedGroups = []
      let selectedContents = []
      let selectedTemplates = []
      let selectedLibraries = []

      roleItems.forEach(roleItem => {
        let roleValue = roleValues.find(roleValue => roleValue.roleName === roleItem)

        if (roleValue) {
          selectedRoles.push(roleValue.role)
        }
      })

      groupItems.forEach(groupItem => {
        let groupValue = groupValues.find(groupValue => groupValue.groupName === groupItem)

        if (groupValue) {
          selectedGroups.push(groupValue.group)
        }
      })

      contentItems.forEach(contentItem => {
        let contentValue = contentValues.find(contentValue => contentValue.contentName === contentItem)

        if (contentValue) {
          selectedContents.push(contentValue.content)
        }
      })

      templateItems.forEach(templateItem => {
        let templateValue = templateValues.find(templateValue => templateValue.templateName === templateItem)

        if (templateValue) {
          selectedTemplates.push(templateValue.template)
        }
      })

      libraryItems.forEach(libraryItem => {
        let libraryValue = libraryValues.find(libraryValue => libraryValue.libraryName === libraryItem)

        if (libraryValue) {
          selectedLibraries.push(libraryValue.library)
        }
      })

      results.push({
        email: row[indexes.email],
        firstName: row[indexes.firstName],
        lastName: row[indexes.lastName],
        selectedRoles: selectedRoles,
        selectedGroups: selectedGroups,
        selectedContents: selectedContents,
        selectedTemplates: selectedTemplates,
        selectedLibraries: selectedLibraries
      })
    })

    setUsers(results)
  }

  const hasErrors = useMemo(
    () => {
      for (const [, value1] of Object.entries(addUserContext.userErrors)) {
        for (const [, value2] of Object.entries(value1)) {
          if (Object.values(value2).some(i => i === true)) {
            return true
          }
        }
      }
      return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.userErrors, users]
  )

  const [targetExample, showExample, hideExample] = useModalActionID()
  const [roleTarget, roleShow, roleHide] = useModalActionID()
  const [groupTarget, groupShow, groupHide] = useModalActionID()
  const [contentTarget, contentShow, contentHide] = useModalActionID()
  const [templateTarget, templateShow, templateHide] = useModalActionID()
  const [libraryTarget, libraryShow, libraryHide] = useModalActionID()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd onClick={handleAddItem} show />
      <MenuItem Icon={IconCsv} text="Import CSV" onClick={handleItemImport} show pin />
      <MenuItem Icon={IconDetails} show pin onClick={showExample} />
    </Menu>

  return (
    <BreadcrumbView Icon={IconAdd} text="add">
      <CardFullHeight title="add users" HeaderMenu={HeaderMenu}>
        <div className="d-flex flex-column h-100">
          <div className="flex-fill overflow-auto">
            <ImportCSV ref={refImport} onImport={onImport} />
            <ModalExample target={targetExample} onClose={hideExample} />
            <InviteModalProgress target={progressTarget} progress={inviteAllProgress} onContinue={handleContinue} />
            <UserList users={users} disabled={disabled} onChange={handleChange} onDelete={handleDelete} onInvite={handleInvite} />
          </div>
          <div className="border-top d-flex flex-nowrap py-1">
            <div className="flex-fill">
              <div className="dropdown d-lg-none">
                <button className="btn btn-primary btn-sm m-1" data-bs-toggle="dropdown" disabled={disabled}>
                  <IconMenu />More
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <SelectRoleDropdown selectedRoles={addUserContext.commonRoles} onClick={roleShow} />
                  </li>
                  <li>
                    <SelectGroupDropdown selectedGroups={addUserContext.commonGroups} onClick={groupShow} />
                  </li>
                  <li>
                    <SelectContentDropdown selectedContents={addUserContext.commonContents} onClick={contentShow} />
                  </li>
                  <li>
                    <SelectTemplateDropdown selectedTemplates={addUserContext.commonTemplates} onClick={templateShow} />
                  </li>
                  <li>
                    <SelectLibraryDropdown selectedLibraries={addUserContext.commonLibraries} onClick={libraryShow} />
                  </li>
                </ul>
              </div>
              <div className="d-flex flex-nowrap d-none d-lg-block">
                <SelectRoleButton selectedRoles={addUserContext.commonRoles} small disabled={disabled} onClick={roleShow} />
                <SelectGroupButton selectedRoles={addUserContext.commonGroups} small disabled={disabled} onClick={groupShow} />
                <SelectContentButton selectedContents={addUserContext.commonContents} small disabled={disabled} onClick={contentShow} />
                <SelectTemplateButton selectedTemplates={addUserContext.commonTemplates} small disabled={disabled} onClick={templateShow} />
                <SelectLibraryButton selectedLibraries={addUserContext.commonLibraries} small disabled={disabled} onClick={libraryShow} />
              </div>
            </div>
            <div className="d-flex flex-nowrap">
              <ButtonSecondary text="cancel" disabled={disabled} small onClick={handleBack} />
              <ButtonPrimary text="invite all" disabled={disabled || hasErrors} small onClick={handleInviteAll} />
            </div>
          </div>
        </div>

        <SelectRoleModal
          target={roleTarget}
          selectedRoles={addUserContext.commonRoles}
          onUpdate={addUserContext.updateCommonRoles}
          onHide={roleHide}
        />

        <SelectGroupModal
          target={groupTarget}
          selectedGroups={addUserContext.commonGroups}
          onUpdate={addUserContext.updateCommonGroups}
          onHide={groupHide}
        />

        <SelectContentModal
          target={contentTarget}
          selectedContents={addUserContext.commonContents}
          onUpdate={addUserContext.updateCommonContents}
          onHide={contentHide}
        />

        <SelectTemplateModal
          target={templateTarget}
          selectedTemplates={addUserContext.commonTemplates}
          onUpdate={addUserContext.updateCommonTemplates}
          onHide={templateHide}
        />

        <SelectLibraryModal
          target={libraryTarget}
          selectedLibraries={addUserContext.commonLibraries}
          onUpdate={addUserContext.updateCommonLibraries}
          onHide={libraryHide}
        />
      </CardFullHeight>
    </BreadcrumbView>
  )
}
