import React, { useContext, useMemo, useRef, useState } from 'react'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AuthContext } from '../../../../../../context/auth.context'
import { useDataContext } from '../../data-context'
import { useApiFormNodeTemplate, useApiListNodeTemplateCategory, useNodeCopyProgress } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { pathNodeTemplateMap } from '../../common/shared/path-node-template'
import { pathNodeBreadcrumbMap } from '../../common/shared/path-node-breadcrumb'
import { NodeTemplateMenuFull } from '../common/node-template-menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconCopy } from '../../../../../../shared/icon/icon'
import { Modal } from '../../../../../../shared/component/modal'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { NodeCaffeine } from '../../common/shared/node-caffeine'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { DisplaySysAdmin } from '../../../../../../role/role.component'
import { FormSelectOrgaization } from '../../../../../../shared/form/form-control/form-select-organization'
import { FormSelectCategoryTemp } from '../../../../../../shared/form/form-control/form-select-category-temp'
import { NodeCopyProgress } from '../../common/node-copy/node-copy-progress'

export const NodeTemplateCopy = ({ nodeID, parentURL }) => {
  const history = useHistory()

  const refModal = useRef()

  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const apiFormNodeTemplate = useApiFormNodeTemplate()

  const title = useMemo(() => `copy ${apiFormNodeTemplate.form.name}`, [apiFormNodeTemplate.form.name])

  const [organizationID, updateOrganizationID] = useState()
  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allowRedirect = useMemo(() => categoryOrganizationID === authContext.state.organizationID, [categoryOrganizationID])

  useLoad(
    () => {
      authContext.state.organizationID && updateOrganizationID(authContext.state.organizationID)
      authContext.state.organizationID && updateCategoryOrganizationID(authContext.state.organizationID)
    },
    [authContext.state.organizationID]
  )

  const handleMatch = () => apiFormNodeTemplate.get(nodeID)

  const handleOrganization = organizationID => updateCategoryOrganizationID(organizationID)

  const [copiedNodeID, updateCopiedNodeID] = useState()
  const [destLocation, updateDestLocation] = useState()

  const handleConfirm = () => {
    refModal.current.hide()

    if (allowRedirect) {
      let path = pathNodeTemplateMap(dataContext.nodeMap, copiedNodeID)
      history.push(path)
    } else {
      history.push(parentURL)
    }
  }

  const handleComplete = async progress => {
    if (allowRedirect) {
      let node = await apiFormNodeTemplate.get(progress.nodeID)

      updateCopiedNodeID(node.id)

      const moveToPath = ({ map }) => {
        dataContext.unsubscribe(moveToPath)
        const destLocation = pathNodeBreadcrumbMap(map, node.id)
        updateDestLocation(destLocation)
        refModal.current.show()
      }

      dataContext.subscribe(moveToPath)
      dataContext.nodeInsert(node)
    } else {
      refModal.current.show()
    }
  }

  const [start, progress] = useNodeCopyProgress(handleComplete)

  const handleSubmit = async data => {
    if (data.parentID === -1) {
      data.parentID = null
    }

    await start(data)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL, apiFormNodeTemplate.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconCopy} text="copy template" onMatch={handleMatch}>
        <Modal ref={refModal} title={title} confirmText="ok" backdropStatic onConfirm={handleConfirm}>
          <p>
            {apiFormNodeTemplate.form.name} has successfully been copied {destLocation && `to ${destLocation}`}
          </p>
        </Modal>

        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <NodeCaffeine node={apiFormNodeTemplate.form}>
            <Form onSubmit={handleSubmit}>
              <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

              <DisplaySysAdmin>
                <FormSelectOrgaization
                  lable="organization"
                  name="organizationID"
                  organizationID={organizationID}
                  required
                  onChange={handleOrganization}
                />
              </DisplaySysAdmin>

              <FormSelectCategoryTemp
                lable="category"
                name="parentID"
                organizationID={categoryOrganizationID}
                useApiList={useApiListNodeTemplateCategory}
                includeRoot
                required
              />

              <FormText label="name" name="name" defaultValue={apiFormNodeTemplate.form.name} required />

              <FormSubmitBack text="copy template" />
            </Form>

            <NodeCopyProgress progress={progress} />
          </NodeCaffeine>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
