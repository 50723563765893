import React, { useMemo, useState } from 'react'
import { useLoad } from '../../../../../../../shared/hooks/load'
import { FileToImageURL, ImageUrlToBlob } from './hook'
import { ImageEditor } from './image-editor'
import { FileSelect } from '../../../../../../../shared/legacy/file/file-select'
import { ImageThumbnail } from './image-thumbnail'
import { Card } from '../../../../../../../shared/card/card'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../shared/component/button'
import { Menu, MenuDelete, MenuEdit, MenuItem } from '../../../../../../../shared/menu/menu'
import { useFileDownloadContent } from '../../../../../../../shared/legacy/storage/hooks'
import { useDisplaySysAdmin } from '../../../../../../../role/role.component'
import { IconDownload } from '../../../../../../../shared/icon/icon'
import { useDataContext } from '../../../data-context'
import { useApiFormFileContent } from '../../../../../../../api-new/state/storage'

export const ImageThumbnailEdit = ({ asset, onUpdate }) => {
  const apiFormFileContent = useApiFormFileContent()

  const dataContext = useDataContext()

  const assetKey = useMemo(() => (asset ? asset.key : null), [asset])

  const [fileExists, updateFileExists] = useState(false)
  const [imageURL, updateImageURL] = useState()
  const [editImageURL, updateEditImageURL] = useState()
  const [previewImageURL, updatePreviewImageURL] = useState()

  const showCurrent = useMemo(() => !!imageURL, [imageURL])
  const showEdit = useMemo(() => !!editImageURL, [editImageURL])
  const showPreview = useMemo(() => !!previewImageURL, [previewImageURL])

  const displaySysAdmin = useDisplaySysAdmin()

  const loadImage = async () => {
    updateImageURL(null)
    updateEditImageURL(null)
    updatePreviewImageURL(null)

    let file = await apiFormFileContent.getBlob(`${assetKey}/stream-safe`)

    if (file.size) {
      updateFileExists(true)
      let imageURL = await FileToImageURL(file)
      updateImageURL(imageURL)
    }
  }

  useLoad(() => loadImage(), [assetKey])

  const handleCancel = () => {
    updateEditImageURL(null)
    updatePreviewImageURL(null)
  }

  const handleContinue = imageURL => updatePreviewImageURL(imageURL)

  const handleUpdate = async () => {
    let blob = await ImageUrlToBlob(previewImageURL)
    await apiFormFileContent.postArg(`/${assetKey}`, blob)
    onUpdate && onUpdate(blob)

    dataContext.thumbnailCache.add(assetKey, previewImageURL)

    loadImage()
  }

  const handleSelect = async file => {
    let imageURL = await FileToImageURL(file)
    updateEditImageURL(imageURL)
  }

  const handleEdit = () => {
    updatePreviewImageURL(null)
  }

  const fileDownloadContent = useFileDownloadContent()

  const handleDownload = async () => fileDownloadContent(asset.name, asset.key)

  const handleDelete = async () => {
    await apiFormFileContent.delete(`${assetKey}`)
    updateImageURL(null)
    updateEditImageURL(null)
    updatePreviewImageURL(null)
  }

  const handleEditCurrent = () => updateEditImageURL(imageURL)

  const HeaderMenu = () => {
    return (
      <Menu>
        <MenuItem text="download" Icon={IconDownload} onClick={handleDownload} show />
        <MenuEdit onClick={handleEditCurrent} show pin={false} />
        <MenuDelete onClick={handleDelete} show={displaySysAdmin} />
      </Menu>
    )
  }

  return (
    <React.Fragment>
      <Card title="Thumbnail" HeaderMenu={HeaderMenu}>
        {!fileExists && <div>NO FILE FOUND</div>}

        <div>
          {asset.key}
        </div>

        <div>
          {showEdit
            ? <React.Fragment>
                {showPreview
                  ? <React.Fragment>
                      <div className="p-2">
                        <ImageThumbnail imageURL={previewImageURL} width={400} height={200} />
                      </div>
                      <div>
                        <ButtonSecondary onClick={handleCancel}>cancel</ButtonSecondary>
                        <ButtonPrimary onClick={handleEdit}>edit</ButtonPrimary>
                        <ButtonPrimary onClick={handleUpdate}>save thumbnail</ButtonPrimary>
                      </div>
                    </React.Fragment>
                  : <React.Fragment>
                      <div className="p-2">
                        <ImageEditor imageURL={editImageURL} onContinue={handleContinue} onCancel={handleCancel} />
                      </div>
                    </React.Fragment>}
              </React.Fragment>
            : <React.Fragment>
                <div className="p-2">
                  {showCurrent && <ImageThumbnail imageURL={imageURL} width={400} height={200} />}
                </div>
                <div className="p-2">
                  <FileSelect onSelect={handleSelect} accept=".jpeg,.png" maxSize={1024 * 512} />
                </div>
              </React.Fragment>}
        </div>

        <div>Image size ratio 2:1</div>
      </Card>
    </React.Fragment>
  )
}
