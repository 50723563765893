import { useState } from 'react'
import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { useApiListChunk } from '../api-list-chunk'
import { useApiWebsocket } from '../api-websocket'
import { urlArgs } from '../state'

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//DDD
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const useApiListGroup = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/group${urlArgs(args)}`)
export const useApiFormGroup = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/group${urlArgs(args)}`)

export const useApiListAsset = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/asset${urlArgs(args)}`)
export const useApiFormAsset = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/asset${urlArgs(args)}`)

export const useApiListAssetThumbnail = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/asset-thumbnail${urlArgs(args)}`)
export const useApiFormAssetThumbnail = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/asset-thumbnail${urlArgs(args)}`)

export const useApiListAssetUnityPackage = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/asset-unity-package${urlArgs(args)}`)
export const useApiFormAssetUnityPackage = args =>
  useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/asset-unity-package${urlArgs(args)}`)

export const useApiListNodeCourse = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-course${urlArgs(args)}`)
export const useApiFormNodeCourse = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-course${urlArgs(args)}`)

export const useApiListNodeCourseCategory = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-course-category${urlArgs(args)}`)

export const useApiFormNodeCourseCategory = args =>
  useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-course-category${urlArgs(args)}`)

export const useApiListNodeLibraryCategory = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-library-category${urlArgs(args)}`)

export const useApiFormNodeLibraryCategory = args =>
  useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-library-category${urlArgs(args)}`)

export const useApiListNodeTemplateCategory = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-template-category${urlArgs(args)}`)

export const useApiFormNodeTemplateCategory = args =>
  useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-template-category${urlArgs(args)}`)

export const useApiListNodeLibrary = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-library${urlArgs(args)}`)
export const useApiFormNodeLibrary = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-library${urlArgs(args)}`)

export const useApiListNodeTemplate = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-template${urlArgs(args)}`)

export const useApiFormNodeTemplate = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-template${urlArgs(args)}`)

export const useApiFormNodeMove = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-move${urlArgs(args)}`)

export const useApiListNodeDecendant = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-decendant${urlArgs(args)}`)

export const useApiListNodeDecendantContent = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-decendant/content${urlArgs(args)}`)

export const useApiListNodeDecendantTemplate = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-decendant/template${urlArgs(args)}`)

export const useApiListNodeDecendantLibrary = args =>
  useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-decendant/library${urlArgs(args)}`)

export const useApiListNodeOrg = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-org${urlArgs(args)}`)
export const useApiFormNodeOrg = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/node-org${urlArgs(args)}`)

export const useApiListNodeGroup = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-group${urlArgs(args)}`)
export const useApiListNodeUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/node-user${urlArgs(args)}`)
export const useApiListGroupUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/group-user${urlArgs(args)}`)

// export const useApiListUserLogin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/ddd/user-login${urlArgs(args)}`)
export const useApiFormUserLogin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/user-login${urlArgs(args)}`)

export const useApiFormUserInvite = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/ddd/user-invite${urlArgs(args)}`)

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useApiListChunkLogError = args => useApiListChunk(process.env.REACT_APP_API_CONTENT, `api/v1/log-error${urlArgs(args)}`)
export const useApiListLogError = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/log-error${urlArgs(args)}`)
export const useApiFormLogError = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/log-error${urlArgs(args)}`)

export const useApiListChunkLogEvent = args => useApiListChunk(process.env.REACT_APP_API_CONTENT, `api/v1/log-event${urlArgs(args)}`)
export const useApiListLogEvent = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/log-event${urlArgs(args)}`)
export const useApiFormLogEvent = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/log-event${urlArgs(args)}`)

export const useApiListUserLogin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/user-login${urlArgs(args)}`)
// export const useApiFormUserLogin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/user-login${urlArgs(args)}`)

export const useApiListOrganization = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/organization${urlArgs(args)}`)

export const useApiFormOrganization = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/organization${urlArgs(args)}`)

// export const useApiListNodeUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-user${urlArgs(args)}`)
// export const useApiListNodeGroup = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-group${urlArgs(args)}`)

export const useApiListUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/user${urlArgs(args)}`)
export const useApiFormUser = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/user${urlArgs(args)}`)

export const useApiListRoleSyncUserSys = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-sync-user-sys${urlArgs(args)}`)

export const useApiListTradeshow = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/trade-show${urlArgs(args)}`)
export const useApFormTradeshow = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/trade-show${urlArgs(args)}`)

export const useApiListLogin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/login${urlArgs(args)}`)

export const useApiFormLogin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/login${urlArgs(args)}`)
export const useApiListLoginOrgUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/login-org-user${urlArgs(args)}`)

export const useApiFormLoginOrgUser = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/login-org-user${urlArgs(args)}`)

export const useApiListSysAdmin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/login-sys-admin${urlArgs(args)}`)
export const useApiFormSysAdmin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/login-sys-admin${urlArgs(args)}`)
export const useApiListSysAdminOrg = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/org-sys-admin${urlArgs(args)}`)

export const useApiListSendgrid = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/sendgrid-event${urlArgs(args)}`)

export const useApiFormSendgrid = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/sendgrid-event${urlArgs(args)}`)

// export const useApiListGroupUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/group-user${urlArgs(args)}`)

export const useApiListNodeCategory = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-category${urlArgs(args)}`)
export const useApiFormNodeCategory = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-category${urlArgs(args)}`)

////////////////////////////////////////////////////////////////////

// export const useApiListNodeCourse = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-course${urlArgs(args)}`)
// export const useApiFormNodeCourse = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-course${urlArgs(args)}`)

// export const useApiListNodeCourseCategory = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-course-category${urlArgs(args)}`)

// export const useApiFormNodeCourseCategory = args =>
//   useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-course-category${urlArgs(args)}`)

// export const useApiListNodeLibraryCategory = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-library-category${urlArgs(args)}`)

// export const useApiFormNodeLibraryCategory = args =>
//   useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-library-category${urlArgs(args)}`)

// export const useApiListNodeTemplateCategory = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-template-category${urlArgs(args)}`)

// export const useApiFormNodeTemplateCategory = args =>
//   useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-template-category${urlArgs(args)}`)

// export const useApiListNodeLibrary = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-library${urlArgs(args)}`)
// export const useApiFormNodeLibrary = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-library${urlArgs(args)}`)

// export const useApiListNodeTemplate = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-template${urlArgs(args)}`)

// export const useApiFormNodeTemplate = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-template${urlArgs(args)}`)

// export const useApiListAsset = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/asset${urlArgs(args)}`)
// export const useApiFormAsset = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/asset${urlArgs(args)}`)

// export const useApiListAssetThumbnail = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/asset-thumbnail${urlArgs(args)}`)
// export const useApiFormAssetThumbnail = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/asset-thumbnail${urlArgs(args)}`)

// export const useApiListAssetUnityPackage = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/asset-unity-package${urlArgs(args)}`)
// export const useApiFormAssetUnityPackage = args =>
//   useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/asset-unity-package${urlArgs(args)}`)

// export const useApiFormNodeMove = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-move${urlArgs(args)}`)

// export const useApiListNodeDecendant = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-decendant${urlArgs(args)}`)

// export const useApiListNodeDecendantContent = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-decendant/content${urlArgs(args)}`)

// export const useApiListNodeDecendantTemplate = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-decendant/template${urlArgs(args)}`)

// export const useApiListNodeDecendantLibrary = args =>
//   useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-decendant/library${urlArgs(args)}`)

// export const useApiListNodeOrg = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-org${urlArgs(args)}`)
// export const useApiFormNodeOrg = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-org${urlArgs(args)}`)

////////////////////////////////////////////////////////////////////

// export const useApiFormNodeCourse = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-course${urlArgs(args)}`)
export const useApiListNodeQuery = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-meta-query${urlArgs(args)}`)

export const useApiListTemplate = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-template${urlArgs(args)}`)

export const useApiFormTemplate = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-template${urlArgs(args)}`)

export const useApiListNode = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node${urlArgs(args)}`)
export const useApiFormNode = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node${urlArgs(args)}`)

export const useApiFormLoginPurge = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/user-login-purge${urlArgs(args)}`)

export const useApiListContent = () => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/content/node`)
export const useApiFormContent = () => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/content/node`)

export const useApiListContentAsset = () => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/content/asset`)

//////////////////////////////////////////
//ROLE
//////////////////////////////////////////

export const useApiListRoleOrg = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-org${urlArgs(args)}`)

export const useApiFormRoleAccess = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/role-access${urlArgs(args)}`)
export const useApiListRoleScope = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-scope${urlArgs(args)}`)
export const useApiListRoleAccessAudit = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-audit${urlArgs(args)}`)
export const useApiListRole = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role${urlArgs(args)}`)
export const useApiFormRole = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/role${urlArgs(args)}`)
export const useApiListRoleType = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-type${urlArgs(args)}`)
export const useApiListAccess = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-access${urlArgs(args)}`)
export const useApiFormAccess = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/role-access${urlArgs(args)}`)
export const useApiListAccessAudit = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-audit${urlArgs(args)}`)
export const useRoleUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-user${urlArgs(args)}`)

//////////////////////////////////////////
//WEB SOCKET
//////////////////////////////////////////

export const useNodeCopyProgress = onComplete => {
  const [connect] = useApiWebsocket(process.env.REACT_APP_API_CONTENT, 'api/ddd/node-copy/ws')

  const [progress, updateProgress] = useState()

  const handleOpen = () => {}

  const handleMessage = progress => {
    updateProgress(progress)

    if (progress.nodeID) {
      onComplete && onComplete(progress)
    }
  }

  const handleClose = () => {}

  const handleError = () => {}

  const start = async value => {
    const [init, send] = connect(handleOpen, handleMessage, handleClose, handleError)

    await init()
    await send(value)
  }

  return [start, progress]
}

export const useNodeTemplateProgress = onComplete => {
  const [connect] = useApiWebsocket(process.env.REACT_APP_API_CONTENT, 'api/ddd/node-copy/ws/template')

  const [progress, updateProgress] = useState()

  const handleOpen = () => {}

  const handleMessage = progress => {
    updateProgress(progress)

    if (progress.nodeID) {
      onComplete && onComplete(progress)
    }
  }

  const handleClose = () => {}

  const handleError = () => {}

  const start = async value => {
    const [init, send] = connect(handleOpen, handleMessage, handleClose, handleError)

    await init()
    await send(value)
  }

  return [start, progress]
}

export const useNodeCourseProgress = onComplete => {
  const [connect] = useApiWebsocket(process.env.REACT_APP_API_CONTENT, 'api/ddd/node-copy/ws/course')

  const [progress, updateProgress] = useState()

  const handleOpen = () => {}

  const handleMessage = progress => {
    updateProgress(progress)

    if (progress.nodeID) {
      onComplete && onComplete(progress)
    }
  }

  const handleClose = () => {}

  const handleError = () => {}

  const start = async value => {
    const [init, send] = connect(handleOpen, handleMessage, handleClose, handleError)

    await init()
    await send(value)
  }

  return [start, progress]
}

export const useUserInviteProgress = onComplete => {
  const [connect] = useApiWebsocket(process.env.REACT_APP_API_CONTENT, 'api/ddd/user-invite/ws')

  const [progress, updateProgress] = useState(0)

  const handleOpen = () => {}

  const handleMessage = result => {
    updateProgress(result.progress)

    if (result.complete) {
      onComplete && onComplete(progress)
    }
  }

  const handleClose = () => {}

  const handleError = () => {}

  const start = async value => {
    const [init, send] = connect(handleOpen, handleMessage, handleClose, handleError)

    await init()
    await send(value)
  }

  return [start, progress]
}
