import React from 'react'
import { Route } from 'react-router-dom'
import { UserLoginList } from './content/user-login-list'
import { UserLoginEdit } from './content/user-login-edit'
import { UserGroupList } from './content/user-login-edit/user-list-group'
import { UserRecycle } from './content/user-recycle'
import { AnalyticCustomUser } from '../../../../view/common/analytic/page/user/analytic-user-custom'
import { RouteAssessmentResultUser } from '../../../../view/common/assessment/assessment-result-user/route-assessment-result-user'
import { useApiFormUserLogin } from '../../../../api-new/state/content'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { AnalyticUserGeneral } from '../../../../view/common/analytic/page/user/analytic-user-general'
import { LogEventOrg } from '../../../../shared/page/log-event/log-event-org'
import { UserLoginAddNew } from './content/user-login-add-new'
import { UserListContent } from './content/user-login-edit/user-list-content'

export const LayoutUserLogin = () => {
  return (
    <React.Fragment>
      <Route path="/org/user/list" component={UserLoginList} />

      {/* <Route path="/org/user/list/add" component={UserLoginAdd} /> */}
      <Route path="/org/user/list/add" component={UserLoginAddNew} />

      <Route path="/org/user/list/edit/:userID" component={UserLoginEdit} />
      <Route path="/org/user/list/edit/:userID/content" component={UserListContent} />
      <Route path="/org/user/list/edit/:userID/group" component={UserGroupList} />

      <Route path="/org/user/list/recycle" component={UserRecycle} />
      <Route path="/org/user/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormUserLogin} titleParam="email" successPath="/org/user/list" />
      </Route>
      <Route path="/org/user/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormUserLogin} titleParam="email" successPath="/org/user/list/recycle" />
      </Route>
      <Route path="/org/user/list/edit/:userID/analytic-custom" component={AnalyticCustomUser} />
      <Route path="/org/user/list/edit/:userID/analytic-general" component={AnalyticUserGeneral} />
      <Route path="/org/user/list/activity-all">
        <LogEventOrg subject="user" />
      </Route>
      <Route path="/org/user/list/activity-id/:id">
        <LogEventOrg subject="user" />
      </Route>

      <RouteAssessmentResultUser />
    </React.Fragment>
  )
}
