import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useAuthContext } from '../../context/auth.context'

import { useLoad } from '../../shared/hooks/load'
import { useApiListContent } from '../../api-new/state/content'
import { useParamsInt } from '../../shared/hooks/location'
import { pathNodeTemplate } from '../../layout/authorized/org/node-content/common/shared/path-node-template'
import { pathNodeContent } from '../../layout/authorized/org/node-content/common/shared/path-node-content'

export const RedirectContentEdit = () => {
  const history = useHistory()
  const { refreshToken } = useParams()
  const { nodeID } = useParamsInt()

  const authContext = useAuthContext()

  const apiListContent = useApiListContent()

  useLoad(async () => {
    debugger
    if (authContext.state.isAuth) {
      let nodes = await apiListContent.get()

      let node = nodes.find(i => i.id === nodeID)

      if (node && node.metaData) {
        let data = JSON.parse(node.metaData)

        if (data.DataType === 'CATEGORY' || data.DataType === 'COURSE') {
          let path = pathNodeContent(nodes, nodeID)
          history.replace(`${path}/edit`)
          return
        }

        if (data.DataType === 'COURSE_TEMPLATE') {
          let path = pathNodeTemplate(nodes, nodeID)
          history.replace(`${path}`)
          return
        }
      }
    } else if (refreshToken) {
      await authContext.actions.refreshRecreate(refreshToken)
    } else {
      history.replace(`/auth/email?redirect=/redirect/content-edit/${nodeID}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <React.Fragment>REDIRECT NODE EDIT</React.Fragment>
}
