import React, { useEffect, useMemo } from 'react'
import { useGetUniqueRowValues } from './hooks'
import { useAddUserContext } from '../context'
import { useImportContext } from './context'
import { Card } from '../../../../../../../shared/card/card'

export const StepLibrary = () => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const uniqueLibraryNames = useGetUniqueRowValues(importContext.rows, importContext.indexes.library)

  const handleUpdate = (index, libraryName, library) => {
    let newValues = { ...importContext.libraryValues }

    if (library) newValues[index] = { libraryName, library }
    else delete newValues[index]

    importContext.updateLibraryValues(newValues)
  }

  useEffect(
    () => {
      if (Object.keys(importContext.libraryValues).length) {
        return
      }

      let values = {}

      uniqueLibraryNames.forEach((libraryName, index) => {
        let libraryOptions = addUserContext.allLibraries.filter(library => library.name.toLowerCase().includes(libraryName))

        if (libraryOptions.length === 1) {
          let library = libraryOptions[0]
          values[index] = { libraryName, library }
        }
      })

      importContext.updateLibraryValues(values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.allLibraries, uniqueLibraryNames]
  )

  return (
    <React.Fragment>
      <Card title="map libraries">
        {uniqueLibraryNames.map((libraryName, i) =>
          <div key={i} className="m-2">
            <SelectLibrary index={i} label={libraryName} libraryName={libraryName} onUpdate={handleUpdate} />
          </div>
        )}
      </Card>
    </React.Fragment>
  )
}

const SelectLibrary = ({ index, label, libraryName, onUpdate }) => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const displayLibraries = useMemo(() => addUserContext.allLibraries.filter(library => library.name.toLowerCase().includes(libraryName)), [
    addUserContext.allLibraries,
    libraryName
  ])

  const value = useMemo(
    () => {
      let item = importContext.libraryValues[index]
      if (item) {
        return item.library.id
      }
      return -1
    },
    [index, importContext.libraryValues]
  )

  useEffect(
    () => {
      let warning = value === -1 && displayLibraries.length !== 0
      importContext.libraryWarnings[index] = warning
      importContext.updateLibraryWarnings({ ...importContext.libraryWarnings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const showWarning = useMemo(() => !!importContext.libraryWarnings[index], [importContext.libraryWarnings, index])

  const handleChange = e => {
    let id = parseInt(e.target.value)
    let library = addUserContext.allLibraries.find(i => i.id === id)
    onUpdate && onUpdate(index, libraryName, library)
  }

  return (
    <div>
      <label className="form-label">
        {label}
      </label>
      <select className={`form-control ${showWarning && 'border-danger'}`} value={value} onChange={handleChange}>
        <option value={-1}>select</option>
        {displayLibraries.map((library, i) =>
          <option key={i} value={library.id}>
            {library.name}
          </option>
        )}
      </select>
    </div>
  )
}
