import React, { useCallback, useMemo, useState } from 'react'
import { IconDown, IconUp } from '../icon/icon'
import { TextSearch } from './components/text-search'
import { Dropdown, DropdownItem } from './components/dropdown'
import { ActiveItemReadOnly, ActiveItemEditable } from './components/active-item'

const useCallbackDefault = (fn, defaultValue) => {
  return useCallback(
    item => {
      if (fn) {
        return fn(item)
      } else {
        return defaultValue
      }
    },
    [fn, defaultValue]
  )
}

export const SelectTag = ({ data, fnLabel, fnActive, fnReadOnly, fnHidden, onAdd, onRemove }) => {
  const [search, updateSearch] = useState('')
  const [listShow, updateListShow] = useState(false)
  const [menuOver, updateMenuOver] = useState(false)

  const label = useCallbackDefault(fnLabel, '')
  const active = useCallbackDefault(fnActive, false)
  const readOnly = useCallbackDefault(fnReadOnly, false)
  const hidden = useCallbackDefault(fnHidden, false)

  const items = useMemo(() => data || [], [data])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeList = useMemo(() => items.filter(item => active(item)), [items])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeListReadOnly = useMemo(() => activeList.filter(item => readOnly(item)), [activeList])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeListEditable = useMemo(() => activeList.filter(item => !readOnly(item)), [activeList])
  const dropdownList = useMemo(
    () => {
      let hold = items.filter(item => !hidden(item)).filter(item => !active(item)).filter(item => item && item.role.includes(search))
      return hold
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, search]
  )

  const handleAdd = item => {
    onAdd && onAdd(item)
    updateSearch('')
    updateListShow(false)
  }

  const handleRemove = item => {
    onRemove && onRemove(item)
    updateSearch('')
  }

  const handleToggle = () => updateListShow(!listShow)

  const handleEdit = edit => {
    if (edit) updateListShow(edit)
    else handleToggle()
  }

  const handleDelete = () => {
    let item = activeListEditable.pop()
    item && onRemove && onRemove(item)
  }

  const handleBlur = () => {
    if (menuOver) {
    } else {
      updateListShow(false)
    }
  }

  const handleOver = over => updateMenuOver(over)

  return (
    <div onBlur={handleBlur}>
      <div className="d-flex dropdown border">
        <div className="d-flex flex-wrap flex-fill" style={{ padding: '2px' }}>
          {activeListReadOnly.map((item, i) => <ActiveItemReadOnly key={i} item={item} label={label} />)}
          {activeListEditable.map((item, i) => <ActiveItemEditable key={i} item={item} label={label} onRemove={handleRemove} />)}
          <TextSearch value={search} onChange={updateSearch} onEdit={handleEdit} onDelete={handleDelete} onClick={handleToggle} />
        </div>

        <div className="align-items-center d-flex border-left" onClick={handleToggle}>
          {listShow ? <IconUp /> : <IconDown />}
        </div>

        <Dropdown show={listShow} onOver={handleOver}>
          {dropdownList.map((item, i) => <DropdownItem key={i} item={item} label={label} onClick={handleAdd} />)}
        </Dropdown>
      </div>
    </div>
  )
}
