import React from 'react'
import { Route, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormNodeOrg } from '../../../../../../api-new/state/content'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { IconList } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { useDataContext } from '../../data-context'
import { Purge } from '../../../../../../shared/page/page-purge'

export const NodeRecycle = ({ nodeID, useApiList }) => {
  const { url } = useRouteMatch()

  const apiList = useApiList()

  const apiFormNodeOrg = useApiFormNodeOrg()

  const dataContext = useDataContext()

  const { accessDelete, accessPurge } = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const handleRestore = async id => {
    await apiFormNodeOrg.put(`${id}/restore`)
    let node = await apiFormNodeOrg.get(id)
    dataContext.nodeInsert(node)
    if (nodeID) await apiList.get(`${nodeID}/deleted`)
    else await apiList.get('deleted')
  }

  const handleMatch = async () => {
    if (nodeID) await apiList.get(`${nodeID}/deleted`)
    else await apiList.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin" onMatch={handleMatch}>
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiList}>
            <Column field="name" sortable filter />
            <Column field="path" sortable filter />
            <Column field="datatype" header="Type" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={accessDelete} />
                  <MenuPurge to={`${url}/${row.id}/purge`} show={accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/:purgeID/purge`}>
        <Purge useApiForm={useApiFormNodeOrg} titleParam="name" successPath={url} />
      </Route>
    </React.Fragment>
  )
}
