import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { useApiFormTemplate } from '../../../../../../api-new/state/content'
import { useDataContext } from '../../data-context'
import { FormRawJson } from '../../../../../../shared/form/form-control/form-raw-json'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Route, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodeAssetList } from '../../common/assets/node-asset-list'
import { NodeTemplateMenuFull } from '../common/node-template-menu'
import { IconEdit } from '../../../../../../shared/icon/icon'

export const NodeTemplateEditSys = ({ parentURL, nodeID }) => {
  const { url } = useRouteMatch()

  const apiFormTemplate = useApiFormTemplate()

  const dataContext = useDataContext()

  const handleMatch = () => apiFormTemplate.get(nodeID)

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])

  const handleSubmit = async data => {
    let node = await apiFormTemplate.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL, apiFormTemplate.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="sys admin edit" onMatch={handleMatch}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form state={apiFormTemplate.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required />
            <FormRawJson label="metadata" name="metaData" />
            <FormSubmit text="update" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/assets`}>
        <NodeAssetList parentURL={url} nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
