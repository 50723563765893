import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useAuthContext } from '../../context/auth.context'
import { useLoad } from '../../shared/hooks/load'
import { useApiListContent } from '../../api-new/state/content'
import { pathNodeContent } from '../../layout/authorized/org/node-content/common/shared/path-node-content'

export const RedirectCourseDetails = () => {
  const history = useHistory()
  const { nodeID, refreshToken } = useParams()

  const authContext = useAuthContext()

  const apiListContent = useApiListContent()

  useLoad(async () => {
    if (authContext.state.isAuth) {
      let nodes = await apiListContent.get()
      let path = pathNodeContent(nodes, nodeID)
      history.replace(path)
    } else if (refreshToken) {
      await authContext.actions.refreshRecreate(refreshToken)
    } else {
      history.replace(`/auth/email?redirect=/redirect/course-details/${nodeID}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <React.Fragment>REDIRECT NODE EDIT</React.Fragment>
}
