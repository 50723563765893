import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { Menu, MenuAdd, MenuDelete } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListNodeUser } from '../../../../../../api-new/state/content'
import { IconGroup } from '../../../../../../shared/icon/icon'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { PermissionNodeUserForUserModal } from '../../../../../../shared/permission-modal/permission-node-user-for-user-modal'

export const UserListContent = () => {
  const { userID } = useParamsInt()

  const apiListNodeUser = useApiListNodeUser()

  const [target, show, hide] = useModalActionID()

  const handleReady = async () => await apiListNodeUser.get(`${userID}/user`)

  const handleAddShow = () => show()
  const handleClose = () => hide()

  const handleClosed = async () => apiListNodeUser.get(`${userID}/user`)

  const handleDelete = async item => {
    await apiListNodeUser.delete(`${item.nodeID}/${item.userID}`)
    await apiListNodeUser.get(`${userID}/user`)
  }

  const roleGroupUser = useRoleAccessOrganization(ROLE_ORG.GROUP_USER)

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add content" onClick={handleAddShow} show={roleGroupUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <PermissionNodeUserForUserModal target={target} userID={userID} onClose={handleClose} onClosed={handleClosed} />
      <BreadcrumbView Icon={IconGroup} text="content">
        <CardFullHeightScrollY title="user content" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListNodeUser} hideFloatingFilter onReady={handleReady}>
            <Column field="nodeName" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleGroupUser.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
